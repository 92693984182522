import { createAutoAnimatePlugin } from '@formkit/addons'
import { de, en, fr, nl } from '@formkit/i18n'
import { generateClasses } from '@formkit/themes'

import { defineFormKitConfig } from '@formkit/vue'

function defaultToNull(node: FormKitNode): void {
  node.hook.input((value, next): any => {
    if (value === undefined)
      return next(null)
    return next(value)
  })
}

export default defineFormKitConfig({

  // rules: {},
  plugins: [
    createAutoAnimatePlugin(),
    // @ts-expect-error: Formkit types aren't up to date
    defaultToNull,
  ],
  locales: {
    nl,
    en,
    fr,
    de,
  },
  locale: 'nl',
  config: {
    classes: generateClasses({
      global: {
        outer: 'text-1.4rem',
        message: 'text-message-error mt-.4rem',
        input: `p-1.2rem 
        block-light:bg-black/10 
        block-light:placeholder-accent-black/20 
        signature-page:block-light:bg-signature-black 
        signature-page:block-light:placeholder-accent-white/20 
        bg-white/10 border-transparent 
        w-full mt-.4rem border-px transition-border-color 
        transition-shadow focus:(outline-none shadow-starline border-starline-primary signature-page:shadow-signature signature-page:border-signature)`,
      },
      textarea: {
        input: 'resize-none min-h-13rem',
      },
      checkbox: {
        input: '$reset hidden',
        wrapper: 'flex gap-1.2rem',
      },
      submit: {
        input: '$reset signature-page:bg-white/10 signature-page:text-white signature-page:block-light:!bg-signature-black signature-page:block-light:!text-white text-1.3rem tracking-[.4rem] uppercase w-full py-1rem px-2.5rem transition block-light:(bg-neutral-black text-neutral-white) border-px bg-neutral-white text-neutral-black hover:(signature-page:shadow-signature signature-page:border-signature shadow-starline border-starline-primary) border-transparent',
      },
    }),
  },
  // etc.
})
